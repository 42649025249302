import React from 'react';
import '../Button/ButtonClasic.css';

const Button = props => {
    return (
        <>
            <button className='btn_clasic' onClick={props.click}>{props.name}</button>
        </>
    )
}

export default Button;
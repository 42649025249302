import React from 'react';
import styles from './Service.module.css';
import Button from '../Button/ButtonClasic.js';
import {
    ServiceContainer,
    InformationBox,
    Icon,
    CloseIcon,
} from './ServiceElements.js';

export default class ElementService extends React.Component {

    state = {
        isOpen: false,
    }

    handleClick = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    render() {
        const firstText = this.props.information[0].text;
        const information = this.props.information[1];
        const offertBox = this.props.information.map(info =>
            info.id >= 2 && info.id <= 5 ? <div key={info.id} className={styles.ofertBox}>
                <p>{info.text}</p>
                <ul>
                    {info.list.map(element => <li key={element}>{element}</li>)}
                </ul>
            </div> : null
        );
        const information2 = this.props.information[5];

        return (
            <div className={styles.box}>
                <img src={this.props.image} alt="" />
                <div className={styles.title}>
                    <h3>{this.props.name}</h3>
                </div>
                <div className={styles.info}>
                    <h3>{this.props.name}</h3>
                    <p>{this.props.text}</p>
                    {this.props.information.length !== 0 ? <Button name="więcej" click={this.handleClick} /> : null}
                </div>

                <ServiceContainer isOpen={this.state.isOpen}>
                    <InformationBox>
                        <Icon>
                            <CloseIcon onClick={this.handleClick} />
                        </Icon>
                        {this.props.styleMethod === "container" ? <div className={styles.container}>
                            <h1>{this.props.name}</h1>
                            {this.props.information.map((info) => {
                                return (
                                    <div key={info.id}>
                                        {info.title !== "" ? <h2>{info.title}</h2> : null}
                                        {info.text !== "" ? <p>{info.text}</p> : null}
                                        {info.list.length > 0 ? <ul>
                                            {info.list.map(element => {
                                                return (
                                                    <li key={element}>{element}</li>
                                                )
                                            })}
                                        </ul> : null}
                                    </div>
                                );
                            })}
                        </div> : null}
                        {this.props.styleMethod === "column" ? <div className={styles.column}>
                            <h1>{this.props.name}</h1>
                            <p>{firstText}</p>
                            <div className={styles.serviceBox}>
                                <h2>{information.title}</h2>
                                <div className={styles.containerBox}>
                                    {offertBox}
                                </div>
                            </div>
                            <div className={styles.serviceBox}>
                                <h2>{information2.title}</h2>
                                <div className={styles.containerBox}>
                                    <div className={styles.ofertBox2}>
                                        <ul>
                                            {this.props.information[5].list.map(element => <li key={element}>{element}</li>)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> : null}
                    </InformationBox>
                </ServiceContainer>
            </div>
        )
    }
}


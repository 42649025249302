import React from 'react';
import { FaBars } from 'react-icons/fa';
import Logo from './LOGO.png';
import './Navbar.css';
import {
    Nav,
    NavbarContainer,
    NavLogo,
    MobileIcon,
    NavMenu,
    NavLinks,
    NavItem,
    NavBtnLink,
    NavBtn
} from './NavbarElements'

const Navbar = props => {
    return (
        <>
            <Nav>
                <NavbarContainer>
                    <NavLogo to='start'>
                        <img className="logo" src={Logo} alt='' />
                    </NavLogo>
                    <MobileIcon onClick={props.click}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to="start">
                                Start
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="about">
                                O nas
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="service">
                                Usługi
                            </NavLinks>
                        </NavItem>
                    </NavMenu>
                    <NavBtn>
                        <NavBtnLink to="contact">
                            Kontakt
                        </NavBtnLink>
                    </NavBtn>
                </NavbarContainer>
            </Nav>
        </>
    )
}

export default Navbar;
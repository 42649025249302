import React from 'react';
import './Footer.css';
import Logo from '../Navbar/LOGO.png';

class Footer extends React.Component {

    state = {
        date: new Date().getFullYear(),
    }

    render() {
        return (
            <section className="sectionFooter" id="footer">
                <footer className="footer">
                    <div className="l-footer">
                        <h1>
                            <img src={Logo} alt="" />
                        </h1>
                        <p>
                            Jesteśmy młodą firmą z dużym doświadczeniem w dziedzinie elektrotechniki i automatyki przemysłowej. Mamy na koncie liczne realizacje dla największych zakładów przemysłowych, a nasi Klienci mogą liczyć na niezawodność, bezpieczeństwo i nowatorskość proponowanych rozwiązań.
                        </p>
                    </div>
                    <ul className="r-footer">
                        <li>
                            <h2>
                                Kontact
                            </h2>
                            <ul className="box">
                                <li>+48 534 733 405</li>
                                <li>+48 512 192 210</li>
                                <li>+48 536 550 969</li>
                                <li>biuro@elthor.pl</li>
                                <li>NIP: 8992891933</li>
                            </ul>
                        </li>
                        <li className="features">
                            <h2>
                                Oferta
                            </h2>
                            <ul className="box h-box">
                                <li><a href="#service">Montaże</a></li>
                                <li><a href="#service">Serwisy</a></li>
                                <li><a href="#service">Automatyka</a></li>
                                <li><a href="#service">Uruchomienia</a></li>
                                <li><a href="#service">Instalacje teletechniczne</a></li>
                                <li><a href="#service">Prefabrykacja</a></li>
                            </ul>
                        </li>
                        <li>
                            <h2>
                                Elthor
                            </h2>
                            <ul className="box">
                                <li><a href="#about">O nas</a></li>
                                <li><a href="#contact">Kontakt</a></li>
                            </ul>
                        </li>
                    </ul>
                    <div className="b-footer">
                        <p>
                            © {this.state.date} Elthor - Realizacja Artur Szejna
                        </p>
                    </div>
                </footer>
            </section>
        )
    }
}

export default Footer;
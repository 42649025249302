import React from 'react';
import './contact.css';
import { FaPhone, FaTelegramPlane, FaHome } from 'react-icons/fa';

import Map from '../Map/Map';

const Contact = () => {
    return (
        <section className="sectionContact" id="contact">
            <div className="contact">
                <h1 className="heading">Zapraszamy do kontaktu</h1>
                <div className="boxContainer">
                    <div className="box">
                        <svg>
                            <FaPhone />
                        </svg>
                        <h3>Zadzwoń do nas</h3>
                        <p>+48 534 733 405 - Maciej Rąk</p>
                        <p>+48 512 192 210 - Marcin Szejna</p>
                        <p>+48 536 550 969 - Marcin Bujak</p>
                    </div>
                    <div className="box">
                        <svg>
                            <FaTelegramPlane />
                        </svg>
                        <h3>Napisz do nas</h3>
                        <p id="mail" onClick={() => {
                            let input = document.querySelector("#mail");
                            input.select();
                        }}>biuro@elthor.pl</p>
                    </div>
                    <div className="box">
                        <svg>
                            <FaHome />
                        </svg>
                        <h3>Adres</h3>
                        <p>Wodzisławska 16/A2,</p>
                        <p>50-561 Wrocław</p>
                    </div>
                </div>
                <Map />

            </div>
        </section>
    )
}

export default Contact;
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

export const ServiceContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    z-index: 500;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`

export const InformationBox = styled.div`
    background-color: var(--main_background);
    position: relative;
    padding: 25px 50px;
    border-radius: 25px;
    margin: 0 30px;

    @media (max-width: 711px) {
        padding: 30px 30px;
    }
`

export const CloseIcon = styled(FaTimes)`
    color: #fff;
    transition: 0.2s ease-in-out;

    &:hover {
        color: var(--second_background);
        transition: 0.2s ease-in-out;
    }
`

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`
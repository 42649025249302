import React from 'react';
import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
    SideBtnWrap,
    SidebarRoute
} from './SidebarElements';

const Sidebar = props => {
    return (
        <SidebarContainer isOpen={props.isOpen}>
            <Icon>
                <CloseIcon onClick={props.click} />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="start" onClick={props.click}>Start</SidebarLink>
                    <SidebarLink to="about" onClick={props.click}>O nas</SidebarLink>
                    <SidebarLink to="service" onClick={props.click}>Oferta</SidebarLink>
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute to="contact" onClick={props.click}>Kontakt</SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar;
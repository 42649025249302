
import React from 'react';
import './App.css';
import { BrowserRouter as Router } from "react-router-dom";

import Sidebar from './components/Sidebar/index.js';
import Navbar from './components/Navbar/Navbar.js';
import Header from './components/Header/Header.js';
import About from './components/About/About.js';
import Service from './components/Service/Services.js';
import Contact from './components/Contact/Contact.js';
import Footer from './components/Footer/Footer.js';

class App extends React.Component {

  state = {
    isOpen: true,
    form: "main"
  }

  handleClick = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  handleChangeForm(form) {
    if (this.state.form !== form) {
      this.setState({
        form: form
      })
    }
  }

  render() {
    return (
      <Router>
        <Sidebar click={this.handleClick} isOpen={this.state.isOpen} />
        <Navbar click={this.handleClick} changeForm={this.handleChangeForm} />
        {this.state.form === "main" ?
          <>
            <Header />
            <About />
            <Service />
            <Contact />
            <Footer />
          </> : null
        }
        {
          this.state.form === "realizations" ?
            <>

            </> : null
        }
      </Router>
    )
  }
}

export default App;

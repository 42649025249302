import React from 'react';
import './button.css';
import { FaLongArrowAltRight } from 'react-icons/fa';

const Button = props => {
    return (
        <div className="btn-container">
            <button className='btn'>
                <span className="text">{props.name}</span>
                <div className="icon-container">
                    <div className="icon icon--left">
                        <svg>
                            <FaLongArrowAltRight />
                        </svg>
                    </div>
                    <div className="icon icon--right">
                        <svg>
                            <FaLongArrowAltRight />
                        </svg>
                    </div>
                </div>
            </button>
        </div>
    )
}

export default Button;
import React from 'react';
import styles from './Header.module.css';
import Button from '../Button/Button.js';

const Header = probs => {
    return (
        <section className={styles.sectionHome} id="start">
            <div className={styles.home}>
                <h1>Specjaliści w elektryce <br /> i automatyce przemysłowej</h1>
                <a href="#contact"><Button name="Wyślij zapytanie" /></a>
            </div>
        </section>
    );
}

export default Header;
import React from 'react';
import styles from './Service.module.css';

import serwisy from './Images/serwisy.jpg';
import prefabrykacja from './Images/prefabrykacja.jpg';
import montaz from './Images/montaz.jpg';
import automatyka from './Images/automatyka.jpg';
import uruchomienia from './Images/uruchomienia.jpeg';
import teletechnika from './Images/teletechnika.jpeg';


import ServiceBox from './Service.js';

class Service extends React.Component {

    state = {
        isOpen: true,
    }

    handleClick = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    services = [
        {
            id: 1,
            name: "Montaże",
            text: "Prowadzimy pełny zakres prac montażowych na obiektach przemysłowych, takich jak: zakłady produkcyjne, hale magazynowe itp.",
            styleMethod: "container",
            image: montaz,
            information: [
                {
                    id: 1,
                    title: "",
                    text: "Prowadzimy pełny zakres prac montażowych na obiektach przemysłowych, takich jak: zakłady produkcyjne, hale magazynowe itp.",
                    list: []
                }, {
                    id: 2,
                    title: "",
                    text: "Zakres usług montażowych obejmuje:",
                    list: ["Posadowienie i montaż rozdzielnic nn i Sn,", "Montaż mostów szynowych i szynoprzewodów,", "Retrofity wyłączników nn i Sn,", "Motaż tras kablowych,", "Układanie kabli,", "Instalacje Ppoż,", "Wykonywanie głowic, muf kablowych,", "Posadowienia i podłączenia transformatorów,", "Montaż baterii kondensatorów."],
                }, {
                    id: 3,
                    title: "",
                    text: "Wszystkie nasze prace objętę są gwarancją oraz protokołami z wykonywanych pomiarów.",
                    list: []
                }
            ]
        }, {
            id: 2,
            name: "Serwisy",
            text: "Oferujemy usługi serwisowe dla instalacji nn i Sn.",
            styleMethod: "column",
            image: serwisy,
            information: [
                {
                    id: 1,
                    title: "",
                    text: "Oferujemy usługi serwisowe dla instalacji nn i Sn.",
                    list: []
                }, {
                    id: 2,
                    title: "Przeglądy",
                    text: "Wyłączników nn i Sn:",
                    list: [
                        "Sprawdzanie zabezpieczeń od strony pierwotnej jak i wtórnej,",
                        "Konserwacja mechaniczna,",
                        "Testy funkcjonalne,",
                        "Pomiar rezystancji styków wyłącznika,",
                        "Badanie niejednoczesności zadziałania styków,",
                        "Usuwanie zanieczyszczeń.",
                    ]
                }, {
                    id: 3,
                    title: "",
                    text: "Rozdzielnice:",
                    list: [
                        "Sprawdzanie dokręcenia śrub,",
                        "Pomiar rezystancji izolacji toru głównego,",
                        "Czyszczenie i odkurzanie",
                        "Badanie termowizyjne,",
                        "Sprawdzanie działania układów automatyki,",
                        "Oględziny i diagnostyka."
                    ]
                }, {
                    id: 4,
                    title: "",
                    text: "Kompensacja mocy biernej:",
                    list: ["Czyszczenie i odkurzanie,", "Sprawdzanie dokręcenia śrub,", "Badanie termowizyjne,", "Pomiary pojemności i czasów rozładowań,", "Pomiar prądów roboczych,", "Pomiar rezystancji izolacji zasilania."],
                }, {
                    id: 5,
                    title: "",
                    text: "Transformatory:",
                    list: ["Pomiar rezystancji uzwojeń,", "Pomiar rezystancji izolacji,", "Sprawdzanie połączeń śrubowych,", "Czyszczenie i odkurzanie,", "Badania termowizyjne."],
                }, {
                    id: 6,
                    title: "Oferujemy również",
                    text: "",
                    list: ["Pomiary elektryczne (Rezystancja izolacji, skuteczność zadziałania zabezpieczeń, pomiar ciągłości przewodów ochronnych, próby napięciowe, testy HV),", "Diagnostyka usterek,", "Usuwanie awarii."]
                }
            ],
        }, {
            id: 3,
            name: "Automatyka",
            text: "Zajmujemy się szeroko pojętą automatyką przemysłową, doświadczenie budowy systemów opieramy na sterownikach firm: ABB, Siemens, Wago.",
            styleMethod: "container",
            image: automatyka,
            information: [
                {
                    id: 1,
                    title: "",
                    text: "Zajmujemy się szeroko pojętą automatyką przemysłową, doświadczenie budowy systemów opieramy na sterownikach firm: ABB, Siemens, Wago.",
                    list: []
                }, {
                    id: 2,
                    title: "",
                    text: "Z zakresu automatyki oferujemy:",
                    list: ["Układy samoczynnego załączenia rezerw,", "Wizualizacja procesów produkcyjnych,", "Tworzenie oraz modyfikacja programów PLC,", "Relokacje maszyn, linii produkcyjnych."],
                }
            ]
        }, {
            id: 4,
            name: "Uruchomienia",
            text: "Wykonujemy kompletny zakres prac uruchomień, poprzedzony szeregiem testów automatyki, sygnalizacji wizualnej i akustycznej.",
            styleMethod: "container",
            image: uruchomienia,
            information: [
                {
                    id: 1,
                    title: "Wykonujemy kompletny zakres prac uruchomień, poprzedzony szeregiem testów automatyki, sygnalizacji wizualnej i akustycznej.",
                    text: "Realizujemy uruchomienia:",
                    list: [
                        "rozdzielnic,",
                        "transformatorów,",
                        "kompensatory mocy biernej,",
                        "układy automatyki."
                    ]
                }, {
                    id: 2,
                    title: "",
                    text: "Wprowadzamy nastawy zabezpieczeń wyłączników, transformatorów, programujemy regulatory mocy biernej.",
                    list: [],
                }
            ]
        }, {
            id: 5,
            name: "Instalacje teletechniczne",
            text: "Dzięki propoonowanym przez nas rozwiązaniom w zakresie teletechniki Wasz obiekt, może podwyższyć prędkość transmisji danych oraz zwiększyć ochronę.",
            styleMethod: "container",
            image: teletechnika,
            information: [
                {
                    id: 1,
                    title: "Dzięki propoonowanym przez nas rozwiązaniom w zakresie teletechniki Wasz obiekt, może podwyższyć prędkość transmisji danych oraz zwiększyć ochronę.",
                    text: "Wykonujemy systemy takie jak:",
                    list: [
                        "telewizja przemysłowa / Telewizja dozorowa – CCTV,",
                        "system sygnalizujący włamania i napady,",
                        "system kontroli dostępu oraz rejestracji czasu pracy,",
                        "sieci, okablowanie, strukturalne."
                    ]
                }
            ]
        }, {
            id: 6,
            name: "Prefabrykacja",
            text: "Specjalizujemy się w prefabrykacji rozdzielnic elektrycznych niskiego napięcia. Nasze rozwiązania zachowują wysoki poziom estetyki i wykonania.",
            styleMethod: "container",
            image: prefabrykacja,
            information: [
                {
                    id: 1,
                    title: "",
                    text: "Specjalizujemy się w prefabrykacji rozdzielnic elektrycznych niskiego napięcia. Nasze rozwiązania zachowują wysoki poziom estetyki i wykonania.",
                    list: []
                }, {
                    id: 2,
                    title: "",
                    text: "Nasi klienci mogą być pewni wykonania naszej usługi kompleksowo. Dzięki zdobytemu przez nas doświadczeniu, jesteśmy w stanie dostosować się do potrzeb najbardziej wymagających klientów.",
                    list: [],
                }
            ]
        },
    ]

    allServices = this.services.map(service =>
        <ServiceBox
            key={service.id}
            name={service.name}
            text={service.text}
            image={service.image}
            information={service.information}
            styleMethod={service.styleMethod}

        />
    )

    render() {
        return (
            <section className={styles.sectionService} id="service" >
                <div className={styles.service}>
                    <h1 className="heading">Nasza oferta</h1>
                    <div className={styles.boxContainer}>
                        {this.allServices}
                    </div>
                </div>
            </section >
        );
    }

}

export default Service;
import React from 'react';
import { GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow } from 'react-google-maps';

class Map extends React.Component {

    state = {
        address: "Wodzisławska 16/A2, 50-561 Wrocław",
        city: "",
        area: "",
        state: "",
        zoom: 16,
        height: 400,
        mapPosition: {
            lat: 51.08157,
            lng: 17.08961,
        },
        markerPosition: {
            lat: 51.08157,
            lng: 17.08961,
        }
    }

    render() {
        const MapWithAMarker = withScriptjs(withGoogleMap(props => (
            <GoogleMap
                defaultZoom={this.state.zoom}
                defaultCenter={this.state.mapPosition}
            >
                <Marker position={this.state.markerPosition}>
                    <InfoWindow>
                        <div>
                            {this.state.address}
                        </div>
                    </InfoWindow>
                </Marker>
            </GoogleMap>
        )))

        return (
            <div>
                <MapWithAMarker
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCY-cvIIFgeyVZ-WQv0XJcfxd6SBdkgNpo&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px`, width: `80%`, margin: `auto` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
            </div>
        )
    }
}

export default Map;
import React from 'react';
import styles from './About.module.css';
import image from './Images/banner4.jpeg';
import Button from '../Button/ButtonClasic.js';

class about extends React.Component {

    state = {
        isClicked: false,
    }

    handleClick = () => {
        this.setState({
            isClicked: !this.state.isClicked
        })
    }

    render() {
        return (
            <section className={styles.sectionAbout} id="about">
                <div className={styles.about}>
                    <div className={styles.row}>
                        <div className={styles.image}>
                            <img src={image} alt="" />
                        </div>

                        <div className={styles.content}>
                            <h1 className="heading">Trochę o nas</h1>
                            <p>Jesteśmy młodą firmą z dużym doświadczeniem w dziedzinie elektrotechniki i automatyki przemysłowej. Mamy na koncie liczne realizacje dla największych zakładów przemysłowych, a nasi Klienci mogą liczyć na niezawodność, bezpieczeństwo i nowatorskość proponowanych rozwiązań. </p>
                            <p>Dysponujemy wyspecjalizowaną kadrą i nowoczesnym sprzętem, co pozwala nam na podjęcie się każdego wyzwania. Działamy w różnych segmentach przemysłu, do każdego zlecenia podchodząc indywidualnie i z pełnym zaangażowaniem.</p>
                            {this.state.isClicked ? <>
                                <p>Zdobywaliśmy doświadczenie przy współpracy z największymi firmami i zakładami w Polsce z różnych segmentów przemysłu, takich jak energetyczny, górniczy, spożywczy, chemiczny itp.</p>
                                <p>Do naszych usług zalicza się:</p>
                                <ul>
                                    <li>montaż</li>
                                    <li>serwis</li>
                                    <li>automatyka</li>
                                    <li>uruchomienia</li>
                                    <li>instalacje teletechniczne</li>
                                    <li>prefabrykacja</li>
                                </ul>
                                <br /><br />
                            </> : null}
                            <Button name={this.state.isClicked ? "Czytaj mniej..." : "Czytaj więcej..."} click={this.handleClick} />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default about;